import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import LocaleContext from '../context/localeContext';
import { BlogContent } from '../components/Content';
import Hero from '../components/Hero';
import Section from '../components/Section';
import ReportDownloadSection from '../components/ReportDownloadSection';

const BlogPost = ({
  title,
  date,
  heroImage,
  downloads,
  content,
  contentComponent,
  tags,
}) => {
  const { locale } = useContext(LocaleContext);
  const ContentComponent = contentComponent ?? BlogContent;

  const formattedDate = DateTime.fromISO(date)
    .setLocale(locale)
    .toLocaleString(DateTime.DATE_FULL);

  return (
    <>
      <Hero
        image={heroImage}
        content={`${formattedDate} \n## ${title}`}
        autoHeaderText={false}
      />
      {downloads && <ReportDownloadSection downloads={downloads} />}
      <Section colorScheme="white" overlayLength="large">
        <div className="container py-12">
          <ContentComponent content={content} />
          {tags && tags.length ? (
            <div className="mt-2">
              <h4>Tags</h4>
              <ul>
                {tags.map((tag) => (
                  <li key={tag + `tag`}>
                    {/* TODO: Link to some page showing posts with this link? */}
                    <span>{tag}</span>
                  </li>
                ))}
              </ul>
            </div>
          ) : null}
        </div>
      </Section>
    </>
  );
};

BlogPost.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  heroImage: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
    .isRequired,
  downloads: PropTypes.arrayOf(PropTypes.object),
  content: PropTypes.object.isRequired,
  tags: PropTypes.arrayOf(PropTypes.string),
};

export default BlogPost;
